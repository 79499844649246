/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  {
    key: 'category',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'leistungsdiagnostik', label: 'Leistungsdiagnostik' },
      { option: 'bikefitting', label: 'Bikefitting' },
      { option: 'coaching', label: "Coaching" },
      { option: 'kurse', label: 'Kurse' },
      { option: 'guides', label: "Guides" },
      { option: 'aerofitting', label: "Aerofitting" },
      { option: 'events', label: "Veranstaltungen" }, 
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      // label: 'Services',
      label: 'Dienstleistungen',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: true,
    },
    saveConfig: {
      label: 'Wählen Sie die Dienstleistungskategorie für diesen Eintrag aus',
      placeholderMessage: 'Wähle eine Option…',
      isRequired: true,
      requiredMessage: 'Sie müssen eine Kategorie auswählen.',
    },
  },

  {
    key: 'bikeTypes',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { label: 'Mountainbike', option: 'mountainBike' },
      { label: 'Rennrad', option: 'roadBike' },
      { label: 'Triathlon', option: 'triathlon' },
      { label: 'Cross/Gravel', option: 'crossGravel' },
      { label: 'Bahnrad/Fixie', option: 'trackBike' },
      { label: 'Downhill bike', option: 'downhillBike' },
      { label: 'Citybike', option: 'cityBike' },
      { label: 'Sonstige', option: 'others' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      // label: 'Bicycle types',
      label: 'Fahrradtypen',
      searchMode: 'has_any',
      group: 'primary',
    },
  },

  {
    key: 'isAvailableOnline',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: true, label: 'Online' },
      // { option: false, label: 'Not Online' },
      { option: false, label: 'Nicht Online' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      label: 'Online',
      group: 'primary',
    },
  },

  // {
  //   key: 'filterAttributes',
  //   scope: 'public',
  //   schemaType: 'multi-enum',
  //   enumOptions: [
  //     // 1
  //     { label: "Leistungsdiagnostik", option: "L1", cat: 1, isLabel: true },

  //     { label: "Ort der Durchführung", option: "L1.1", cat: 1, isSubLabel: true },
  //     { label: "Feldtest", option: "leistungsdiagnostik_ortDerDurchführung_fieldTest", cat: 1 },
  //     { label: "Labortest", option: "leistungsdiagnostik_ortDerDurchführung_laboratory", cat: 1 },

  //     { label: "Labortest", option: "L1.2", cat: 1, isSubLabel: true },
  //     { label: "Spiroergonometrie", option: "leistungsdiagnostik_ortDerDurchführung_laboratory_spiroErgometry", cat: 1 },
  //     { label: "Laktat-Stufen-Test", option: "leistungsdiagnostik_ortDerDurchführung_laboratory_lactateProfile", cat: 1 },
  //     { label: "Vo2Max Test", option: "leistungsdiagnostik_ortDerDurchführung_laboratory_vo2MaxTest", cat: 1 },
  //     { label: "Laktat-Stufen-Test", option: "L1.2.1", cat: 1, isSubLabel: true },
  //     { label: "Eigenes Fahrrad", option: "leistungsdiagnostik_ortDerDurchführung_laboratory_lactateProfile_ownBike", cat: 1 },
  //     { label: "Ergometer", option: "leistungsdiagnostik_ortDerDurchführung_laboratory_lactateProfile_ergometer", cat: 1 },

  //     { label: "Vo2Max Test", option: "L1.2.2", cat: 1, isSubLabel: true },
  //     { label: "Eigenes Fahrrad", option: "leistungsdiagnostik_ortDerDurchführung_laboratory_vo2MaxTest_ownBike", cat: 1 },
  //     { label: "Ergometer", option: "leistungsdiagnostik_ortDerDurchführung_laboratory_vo2MaxTest_ergometer", cat: 1 },

  //     //2
  //     { label: "Coaching level", option: "L2", cat: 2, isLabel: true },
  //     { label: "Anfänger", option: "coaching_coachingLevel_recreational", cat: 2 },
  //     { label: "Amateur", option: "coaching_coachingLevel_ambitious", cat: 2 },
  //     { label: "Professionell", option: "coaching_coachingLevel_competitive", cat: 2 },

  //     //3
  //     { label: "Kurs Level", option: "L3", cat: 3, isLabel: true },

  //     { label: "Einsteiger", option: "kurse_coursesLevel_beginner", cat: 3 },
  //     { label: "Fortgeschritten", option: "kurse_coursesLevel_advanced", cat: 3 },
  //     { label: "Experte", option: "kurse_coursesLevel_expert", cat: 3 },

  //     //4
  //     { label: "Ausrichtung", option: "L4", cat: 4, isLabel: true },

  //     { label: "Sightseeing", option: "guides_guidesLevel_sightseeing", cat: 4 },
  //     { label: "Sportlich", option: "guides_guidesLevel_sporty", cat: 4 },

  //     //5
  //     { label: "Ort der Durchführung", option: "L5", cat: 5, isLabel: true },
  //     { label: "Feldtest", option: "aerofitting_fittingLevel_field", cat: 5 },
  //     { label: "Velodrom", option: "aerofitting_fittingLevel_track", cat: 5 },
  //     { label: "Feldtest", option: "L5.1", cat: 5, isSubLabel: true },
  //     { label: "Sattel-Druck-Analyse", option: "aerofitting_fittingLevel_field_pressureAnalysis", cat: 5 },

  //     { label: "Track", option: "L5.2", cat: 5, isSubLabel: true },
  //     { label: "Vorbereitung", option: "aerofitting_fittingLevel_track_preparation", cat: 5 },

  //     //6
  //     { label: "Zusätzliche Services", option: "L6", cat: 6, isLabel: true },

  //     { label: "Video Analyse", option: "bikefitting_bike_VideoBased", cat: 6 },
  //     { label: "Sattel-Druck-Analyse", option: "bikefitting_bike_AnalysisPressure", cat: 6 },
  //     { label: "Schuheinstellung ", option: "bikefitting_bike_shoeAdjustment", cat: 6 },

  //     { label: "Video Analyse", option: "L6.1", cat: 6, isSubLabel: true },
  //     { label: "Rennen", option: "bikefitting_bike_VideoBased_race", cat: 6 },

  //     { label: "Sattel-Druck-Analyse", option: "L6.2", cat: 6, isSubLabel: true },
  //     { label: "Sportlich", option: "bikefitting_bike_AnalysisPressure_athletic", cat: 6 },

  //     { label: "Schuheinstellung ", option: "L6.3", cat: 6, isSubLabel: true },
  //     { label: "Comfortable/Health", option: "bikefitting_bike_shoeAdjustment_comfortableHealth", cat: 6 },

  //     //7
  //     { label: "Kategorie", option: "L7", cat: 7, isLabel: true },

  //     { label: "On the bike", option: "veranstaltungen_eventsLevel_onTheBike", cat: 7 },
  //     { label: "Off the bike", option: "veranstaltungen_eventsLevel_offTheBike", cat: 7 },

  //     { label: "On the bike", option: "L7.1", cat: 7, isSubLabel: true },
  //     { label: "Social ride", option: "veranstaltungen_eventsLevel_onTheBike_socialRide", cat: 7 },
  //     { label: "Hobby Rennen", option: "veranstaltungen_eventsLevel_onTheBike_race", cat: 7 },
  //     { label: "Rennen", option: "veranstaltungen_eventsLevel_onTheBike_officialRace", cat: 7 },
  //     { label: "Radreise", option: "veranstaltungen_eventsLevel_onTheBike_tour", cat: 7 },
  //     { label: "Marathon", option: "veranstaltungen_eventsLevel_onTheBike_marathon", cat: 7 },
  //     { label: "Gran Fondo", option: "veranstaltungen_eventsLevel_onTheBike_granFondo", cat: 7 },
  //     { label: "Trainingsausfahrt", option: "veranstaltungen_eventsLevel_onTheBike_trainingRide", cat: 7 },
  //     { label: "Off the bike", option: "L7.2", cat: 7, isSubLabel: true },
  //     { label: "Seminar", option: "veranstaltungen_eventsLevel_offTheBike_seminar", cat: 7 },
  //     { label: "Workshop", option: "veranstaltungen_eventsLevel_offTheBike_workshop", cat: 7 },
  //     { label: "Vortrag", option: "veranstaltungen_eventsLevel_offTheBike_tripHolidayCamp", cat: 7 },
  //     { label: "Messe/Ausstellung", option: "veranstaltungen_eventsLevel_offTheBike_show", cat: 7 },
  //     { label: "Sonstiges", option: "veranstaltungen_eventsLevel_offTheBike_expo", cat: 7 },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectMultipleFilter',
  //     label: 'Attributes',
  //     searchMode: 'has_any',
  //     group: 'secondary',
  //   },
  // }
]

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:     Unique string. This will be saved to listing's public data on
 *                    EditListingWizard.
 * - label            Label for the listing type. Used as microcopy for options to select
 *                    listing type in EditListingWizard.
 * - transactionType  Set of configurations how this listing type will behave when transaction is
 *                    created.
 *   - process          Transaction process.
 *                      The process must match one of the processes that this client app can handle
 *                      (check src/util/transaction.js) and the process must also exists in correct
 *                      marketplace environment.
 *   - alias            Valid alias for the aforementioned process. This will be saved to listing's
 *                      public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType         Unit type is mainly used as pricing unit. This will be saved to
 *                      transaction's protected data.
 *                      Recommendation: don't use same unit types in completely different processes
 *                      ('item' sold should not be priced the same as 'item' booked).
 * - stockType        This is relevant only to listings with product-selling listing type.
 *                    If set to 'oneItem', stock management is not showed and the listing is
 *                    considered unique (stock = 1).
 *                    Possible values: 'oneItem' and 'multipleItems'.
 *                    Default: 'multipleItems'.
 */

export const listingTypes = [
  // {
  //   listingType: 'daily-booking',
  //   label: 'Daily booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'day',
  //   },
  // },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  {
    listingType: 'hourly-booking',
    label: 'Hourly booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'hour',
    },
  },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  // },
];


// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;

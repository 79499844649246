import { fetchCurrentUser } from '../../ducks/user.duck';
import { setInitialValues as setInitialValuesForPaymentMethods } from '../../ducks/paymentMethods.duck';
import { fetchMangoUser } from '../StripePayoutPage/StripePayoutPage.duck';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import axios from 'axios';

// ================ Action types ================ //

export const SETUP_INTENT_REQUEST = 'app/PaymentMethodsPage/SETUP_INTENT_REQUEST';
export const SETUP_INTENT_SUCCESS = 'app/PaymentMethodsPage/SETUP_INTENT_SUCCESS';
export const SETUP_INTENT_ERROR = 'app/PaymentMethodsPage/SETUP_INTENT_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/PaymentMethodsPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/PaymentMethodsPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/PaymentMethodsPage/STRIPE_CUSTOMER_ERROR';

export const FETCH_CARDS_REQUEST = 'app/PaymentMethodsPage/FETCH_CARDS_REQUEST';
export const FETCH_CARDS_SUCCESS = 'app/PaymentMethodsPage/FETCH_CARDS_SUCCESS';
export const FETCH_CARDS_ERROR = 'app/PaymentMethodsPage/FETCH_CARDS_ERROR';

export const SAVE_USER_REQUEST = 'app/PaymentMethodsPage/SAVE_USER_REQUEST';
export const SAVE_USER_SUCCESS = 'app/PaymentMethodsPage/SAVE_USER_SUCCESS';
export const SAVE_USER_ERROR = 'app/PaymentMethodsPage/SAVE_USER_ERROR';

// ================ Reducer ================ //

const initialState = {
  setupIntentInProgress: false,
  setupIntentError: null,
  setupIntent: null,
  stripeCustomerFetched: false,
  fetchCardsInProgress: false,
  fetchCardsError: null,
  cards: [],
  saveUserInProgress: false,
  saveUserError: null,
};

export default function payoutMethodsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SETUP_INTENT_REQUEST:
      return { ...state, setupIntentInProgress: true, setupIntentError: null };
    case SETUP_INTENT_SUCCESS:
      return {
        ...state,
        setupIntentInProgress: false,
        setupIntentError: null,
        setupIntent: payload,
      };
    case SETUP_INTENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, setupIntentInProgress: false, setupIntentError: null };
    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    case FETCH_CARDS_REQUEST:
      return { ...state, fetchCardsInProgress: true, fetchCardsError: null };
    case FETCH_CARDS_SUCCESS: {
      return {
        ...state,
        fetchCardsInProgress: false,
        cards: payload.cards,
      };
    }
    case FETCH_CARDS_ERROR:
      return { ...state, fetchCardsInProgress: false, fetchCardsError: payload };

    case SAVE_USER_REQUEST:
      return {
        ...state,
        saveUserInProgress: true,
        saveUserError: null,
      };
    case SAVE_USER_SUCCESS:
      return { ...state, saveUserInProgress: false, fetchCardsError: null };
    case SAVE_USER_ERROR:
      return { ...state, saveUserInProgress: false, saveUserError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setupIntentRequest = () => ({ type: SETUP_INTENT_REQUEST });
export const setupIntentSuccess = () => ({ type: SETUP_INTENT_SUCCESS });
export const setupIntentError = e => ({
  type: SETUP_INTENT_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

const fetchCardsRequest = () => ({ type: FETCH_CARDS_REQUEST });
const fetchCardsSuccess = (cards) => ({
  type: FETCH_CARDS_SUCCESS,
  payload: { cards },
});
const fetchCardsError = e => ({ type: FETCH_CARDS_ERROR, error: true, payload: e });

const saveUserRequest = () => ({ type: SAVE_USER_REQUEST });
const saveUserSuccess = () => ({ type: SAVE_USER_SUCCESS });
const saveUserError = e => ({ type: SAVE_USER_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const createStripeSetupIntent = () => (dispatch, getState, sdk) => {
  dispatch(setupIntentRequest());
  return sdk.stripeSetupIntents
    .create()
    .then(response => {
      const setupIntent = response.data.data;
      dispatch(setupIntentSuccess(setupIntent));
      return setupIntent;
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'create-setup-intent-failed');
      dispatch(setupIntentError(error));
      return { createStripeSetupIntentSuccess: false };
    });
};

export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'fetch-stripe-customer-failed');
      dispatch(stripeCustomerError(error));
    });
};

export const fetchCards = (sharetribeUserId) => (dispatch, getState, sdk) => {
  dispatch(fetchCardsRequest());

  return axios.post('/api/mangopay/get-cards', { sharetribeUserId })
    .then(response => {
      return dispatch(fetchCardsSuccess(response.data));
    })
    .catch(e => {
      dispatch(fetchCardsError(e.response && e.response.data ? e.response.data : 'Unknown error'));
      throw e;
    });
};

export const createMangoUser = (params) => (dispatch, getState, sdk) => {
  dispatch(saveUserRequest());

  return axios.post('/api/mangopay/create-user', params)
    .then(response => {
      return dispatch(fetchMangoUser(response.data.sharetribeUserId))
        .then(() => {
          const {
            address,
            country,
            userType,
            nationality,
            birthday,
          } = params;
          return sdk.currentUser
            .updateProfile({
              publicData: {
                address,
                country,
                userType,
                nationality,
                birthday,
              }
            }, {})
            .then(() => {
              dispatch(saveUserSuccess());
            })
            .catch(() => dispatch(saveUserSuccess()));
        })
        .catch(() => dispatch(saveUserSuccess()));
    })
    .catch(e => {
      dispatch(saveUserError(e.response && e.response.data ? e.response.data : 'Unknown error'));
      throw e;
    });
};

export const loadData = () => (dispatch, getState, sdk) => {
  dispatch(setInitialValuesForPaymentMethods());

  return dispatch(fetchCurrentUser()).then(response => {
    const currentUser = getState().user.currentUser;

    if (currentUser) {
      dispatch(fetchCards(currentUser.id.uuid));
    };

    return dispatch(stripeCustomer());
  });
};

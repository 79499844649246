import React from "react";
import css from "./CustomSpinner.module.css"

const CustomSpinner = (props) => {
    const { loading = false } = props;

    if (loading) {
        return <div className={css.wholeContainer}>
            <div className={css.spinnerFullScreen}></div>
            <div className={css.loadingSpinner}></div>
        </div>
    } else {
        return null
    };

};

export default CustomSpinner;
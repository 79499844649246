import pick from 'lodash/pick';
import {
  createStripeAccount,
  updateStripeAccount,
  // fetchStripeAccount,
} from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';

import axios from 'axios';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/StripePayoutPage/SET_INITIAL_VALUES';
export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_ERROR';

export const FETCH_BANK_ACC_REQUEST = 'app/TransactionPage/FETCH_BANK_ACC_REQUEST';
export const FETCH_BANK_ACC_SUCCESS = 'app/TransactionPage/FETCH_BANK_ACC_SUCCESS';
export const FETCH_BANK_ACC_ERROR = 'app/TransactionPage/FETCH_BANK_ACC_ERROR';

export const SAVE_BANK_ACC_REQUEST = 'app/TransactionPage/SAVE_BANK_ACC_REQUEST';
export const SAVE_BANK_ACC_SUCCESS = 'app/TransactionPage/SAVE_BANK_ACC_SUCCESS';
export const SAVE_BANK_ACC_ERROR = 'app/TransactionPage/SAVE_BANK_ACC_ERROR';

export const FETCH_KYC_REQUEST = 'app/TransactionPage/FETCH_KYC_REQUEST';
export const FETCH_KYC_SUCCESS = 'app/TransactionPage/FETCH_KYC_SUCCESS';
export const FETCH_KYC_ERROR = 'app/TransactionPage/FETCH_KYC_ERROR';

export const CREATE_KYC_REQUEST = 'app/TransactionPage/CREATE_KYC_REQUEST';
export const CREATE_KYC_SUCCESS = 'app/TransactionPage/CREATE_KYC_SUCCESS';
export const CREATE_KYC_ERROR = 'app/TransactionPage/CREATE_KYC_ERROR';

export const FETCH_MANGO_USER_REQUEST = 'app/TransactionPage/FETCH_MANGO_USER_REQUEST';
export const FETCH_MANGO_USER_SUCCESS = 'app/TransactionPage/FETCH_MANGO_USER_SUCCESS';
export const FETCH_MANGO_USER_ERROR = 'app/TransactionPage/FETCH_MANGO_USER_ERROR';

export const FETCH_UBO_REQUEST = 'app/TransactionPage/FETCH_UBO_REQUEST';
export const FETCH_UBO_SUCCESS = 'app/TransactionPage/FETCH_UBO_SUCCESS';
export const FETCH_UBO_ERROR = 'app/TransactionPage/FETCH_UBO_ERROR';

export const CREATE_UBO_REQUEST = 'app/TransactionPage/CREATE_UBO_REQUEST';
export const CREATE_UBO_SUCCESS = 'app/TransactionPage/CREATE_UBO_SUCCESS';
export const CREATE_UBO_ERROR = 'app/TransactionPage/CREATE_UBO_ERROR';

// ================ Reducer ================ //

const initialState = {
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  fromReturnURL: false,
  fetchBankAccInProgress: false,
  fetchBankAccError: null,
  bankAcc: {},
  saveBankAccInProgress: false,
  saveBankAccError: null,
  fetchKYCInProgress: false,
  fetchKYCError: null,
  createKYCInProgress: false,
  createKYCError: null,
  kycDocs: [],
  fetchUBOInProgress: false,
  fetchUBOError: null,
  createUBOInProgress: false,
  createUBOError: null,
  UBODocs: [],
  fetchMangoUserInProgress: false,
  fetchMangoUserError: null,
  mangoUser: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true };

    case FETCH_BANK_ACC_REQUEST:
      return { ...state, fetchBankAccInProgress: true, fetchBankAccError: null };
    case FETCH_BANK_ACC_SUCCESS: {
      return {
        ...state,
        fetchBankAccInProgress: false,
        bankAcc: payload.bankAcc,
      };
    }
    case FETCH_BANK_ACC_ERROR:
      return { ...state, fetchBankAccInProgress: false, fetchBankAccError: payload };

    case SAVE_BANK_ACC_REQUEST:
      return {
        ...state,
        saveBankAccInProgress: true,
        saveBankAccError: null,
      };
    case SAVE_BANK_ACC_SUCCESS:
      return { ...state, saveBankAccInProgress: false };
    case SAVE_BANK_ACC_ERROR:
      return { ...state, saveBankAccInProgress: false, saveBankAccError: payload };

    case FETCH_KYC_REQUEST:
      return { ...state, fetchKYCInProgress: true, fetchKYCError: null };
    case FETCH_KYC_SUCCESS: {
      return {
        ...state,
        fetchKYCInProgress: false,
        kycDocs: payload.kyc,
      };
    }
    case FETCH_KYC_ERROR:
      return { ...state, fetchKYCInProgress: false, fetchKYCError: payload };

    case CREATE_KYC_REQUEST:
      return {
        ...state,
        createKYCInProgress: true,
        createKYCError: null,
      };
    case CREATE_KYC_SUCCESS:
      return { ...state, createKYCInProgress: false };
    case CREATE_KYC_ERROR:
      return { ...state, createKYCInProgress: false, createKYCError: payload };

    case FETCH_UBO_REQUEST:
      return { ...state, fetchUBOInProgress: true, fetchUBOError: null };
    case FETCH_UBO_SUCCESS: {
      return {
        ...state,
        fetchUBOInProgress: false,
        UBODocs: payload.ubo,
      };
    }
    case FETCH_UBO_ERROR:
      return { ...state, fetchUBOInProgress: false, fetchUBOError: payload };

    case CREATE_UBO_REQUEST:
      return {
        ...state,
        createUBOInProgress: true,
        createUBOError: null,
      };
    case CREATE_UBO_SUCCESS:
      return { ...state, createUBOInProgress: false };
    case CREATE_UBO_ERROR:
      return { ...state, createUBOInProgress: false, createUBOError: payload };

    case FETCH_MANGO_USER_REQUEST:
      return { ...state, fetchMangoUserInProgress: true, fetchMangoUserError: null };
    case FETCH_MANGO_USER_SUCCESS: {
      return {
        ...state,
        fetchMangoUserInProgress: false,
        mangoUser: payload.user,
      };
    }
    case FETCH_MANGO_USER_ERROR:
      return { ...state, fetchMangoUserInProgress: false, fetchMangoUserError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const savePayoutDetailsRequest = () => ({
  type: SAVE_PAYOUT_DETAILS_REQUEST,
});
export const savePayoutDetailsError = () => ({
  type: SAVE_PAYOUT_DETAILS_ERROR,
});
export const savePayoutDetailsSuccess = () => ({
  type: SAVE_PAYOUT_DETAILS_SUCCESS,
});

const fetchBankAccRequest = () => ({ type: FETCH_BANK_ACC_REQUEST });
const fetchBankAccSuccess = (bankAcc) => ({
  type: FETCH_BANK_ACC_SUCCESS,
  payload: { bankAcc },
});
const fetchBankAccError = e => ({ type: FETCH_BANK_ACC_ERROR, error: true, payload: e });

const saveBankAccRequest = () => ({ type: SAVE_BANK_ACC_REQUEST });
const saveBankAccSuccess = () => ({ type: SAVE_BANK_ACC_SUCCESS });
const saveBankAccError = e => ({ type: SAVE_BANK_ACC_ERROR, error: true, payload: e });

const fetchKYCRequest = () => ({ type: FETCH_KYC_REQUEST });
const fetchKYCSuccess = (kyc) => ({
  type: FETCH_KYC_SUCCESS,
  payload: { kyc },
});
const fetchKYCError = e => ({ type: FETCH_KYC_ERROR, error: true, payload: e });

const createKYCRequest = () => ({ type: CREATE_KYC_REQUEST });
const createKYCSuccess = () => ({ type: CREATE_KYC_SUCCESS });
const createKYCError = e => ({ type: CREATE_KYC_ERROR, error: true, payload: e });

const fetchUBORequest = () => ({ type: FETCH_UBO_REQUEST });
const fetchUBOSuccess = (ubo) => ({
  type: FETCH_UBO_SUCCESS,
  payload: { ubo },
});
const fetchUBOError = e => ({ type: FETCH_UBO_ERROR, error: true, payload: e });

const createUBORequest = () => ({ type: CREATE_UBO_REQUEST });
const createUBOSuccess = () => ({ type: CREATE_UBO_SUCCESS });
const createUBOError = e => ({ type: CREATE_UBO_ERROR, error: true, payload: e });

const fetchMangoUserRequest = () => ({ type: FETCH_MANGO_USER_REQUEST });
const fetchMangoUserSuccess = (user) => ({
  type: FETCH_MANGO_USER_SUCCESS,
  payload: { user },
});
const fetchMangoUserError = e => ({ type: FETCH_MANGO_USER_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const savePayoutDetails = (values, isUpdateCall) => (dispatch, getState, sdk) => {
  const upsertThunk = isUpdateCall ? updateStripeAccount : createStripeAccount;
  dispatch(savePayoutDetailsRequest());

  return dispatch(upsertThunk(values, { expand: true }))
    .then(response => {
      dispatch(savePayoutDetailsSuccess());
      return response;
    })
    .catch(() => dispatch(savePayoutDetailsError()));
};

export const fetchBankAcc = (sharetribeUserId) => (dispatch, getState, sdk) => {
  dispatch(fetchBankAccRequest());

  return axios.post('/api/mangopay/get-bank-acc', { sharetribeUserId })
    .then(response => {
      return dispatch(fetchBankAccSuccess(response.data));
    })
    .catch(e => {
      dispatch(fetchBankAccError(e.response && e.response.data ? e.response.data : 'Unknown error'));
      throw e;
    });
};

export const createBankAcc = (params) => (dispatch, getState, sdk) => {
  dispatch(saveBankAccRequest());

  return axios.post('/api/mangopay/create-bank-acc', params)
    .then(response => {
      return dispatch(fetchBankAcc(response.data.sharetribeUserId))
        .then(() => {
          dispatch(fetchMangoUser(response.data.sharetribeUserId));
        })
        .then(() => {
          const {
            address,
            country,
            userType,
            nationality,
            birthday,
          } = params;
          if (params.isBankOnly) {
            return dispatch(saveBankAccSuccess());
          } else {
            return sdk.currentUser
              .updateProfile({
                publicData: {
                  address,
                  country,
                  userType,
                  nationality,
                  birthday,
                }
              }, {})
              .then(() => {
                dispatch(saveBankAccSuccess());
              })
              .catch(() => dispatch(saveBankAccSuccess()));
          };
        })
        .catch(() => dispatch(saveBankAccSuccess()));
    })
    .catch(e => {
      dispatch(saveBankAccError(e.response && e.response.data ? e.response.data : 'Unknown error'));
      if (e?.response?.data == "Creating bank account failed") {
        const {
          address,
          country,
          userType,
          nationality,
          birthday,
        } = params;
        if (params.isBankOnly) {
          return dispatch(fetchMangoUser(params.sharetribeUser.id.uuid));
        } else {
          return sdk.currentUser
            .updateProfile({
              publicData: {
                address,
                country,
                userType,
                nationality,
                birthday,
              }
            }, {})
            .then(() => {
              dispatch(fetchMangoUser(params.sharetribeUser.id.uuid));
            });
        };
      };
      throw e;
    });
};

export const fetchKYC = (sharetribeUserId) => (dispatch, getState, sdk) => {
  dispatch(fetchKYCRequest());

  return axios.post('/api/mangopay/get-KYC', { sharetribeUserId })
    .then(response => {
      return dispatch(fetchKYCSuccess(response.data));
    })
    .catch(e => {
      dispatch(fetchKYCError(e.response && e.response.data ? e.response.data : 'Unknown error'));
      throw e;
    });
};

export const createKYC = (params) => (dispatch, getState, sdk) => {
  dispatch(createKYCRequest());

  return axios.post('/api/mangopay/create-KYC', params)
    .then(response => {
      return dispatch(fetchKYC(response.data.sharetribeUserId))
        .then(() => {
          dispatch(createKYCSuccess());
        })
        .catch(() => dispatch(createKYCSuccess()));
    })
    .catch(e => {
      dispatch(createKYCError(e.response && e.response.data ? e.response.data : 'Unknown error'));
      throw e;
    });
};

export const fetchMangoUser = (sharetribeUserId) => (dispatch, getState, sdk) => {
  dispatch(fetchMangoUserRequest());

  return axios.post('/api/mangopay/get-user', { sharetribeUserId })
    .then(response => {
      return dispatch(fetchMangoUserSuccess(response.data));
    })
    .catch(e => {
      dispatch(fetchMangoUserError(e.response && e.response.data ? e.response.data : 'Unknown error'));
      throw e;
    });
};

export const fetchUBO = (sharetribeUserId) => (dispatch, getState, sdk) => {
  dispatch(fetchUBORequest());

  return axios.post('/api/mangopay/get-user-ubos', { sharetribeUserId })
    .then(response => {
      return dispatch(fetchUBOSuccess(response.data));
    })
    .catch(e => {
      dispatch(fetchUBOError(e.response && e.response.data ? e.response.data : 'Unknown error'));
      throw e;
    });
};

export const createUBO = (params) => (dispatch, getState, sdk) => {
  dispatch(createUBORequest());

  return axios.post('/api/mangopay/create-ubo', params)
    .then(response => {
      return dispatch(fetchUBO(response.data.sharetribeUserId))
        .then(() => {
          dispatch(createUBOSuccess());
        })
        .catch(() => dispatch(createUBOSuccess()));
    })
    .catch(e => {
      dispatch(createUBOError(e.response && e.response.data ? e.response.data : 'Unknown error'));
      throw e;
    });
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialValues());

  return dispatch(fetchCurrentUser()).then(response => {
    const currentUser = getState().user.currentUser;
    // if (currentUser && currentUser.stripeAccount) {
    //   dispatch(fetchStripeAccount());
    // }
    if (currentUser) {
      dispatch(fetchMangoUser(currentUser.id.uuid));
      dispatch(fetchBankAcc(currentUser.id.uuid));
      dispatch(fetchKYC(currentUser.id.uuid));
      dispatch(fetchUBO(currentUser.id.uuid));
    };

    return response;
  });
};

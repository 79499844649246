import React from 'react';
import { PrimaryButton } from '../Button/Button';
import Page from '../Page/Page';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import css from './WrongUserType.module.css';

const WrongUserType = (props) => {
    const levelRequired = props.restrictAccessTo;

    return <Page title={'Wrong User Type'}>
        <TopbarContainer />
        <div className={css.mainContainer}>
            <h3>Different User Type</h3>
            <div>Sorry, This page only accessable to {levelRequired}s!</div>
            <br />
            <PrimaryButton onClick={() => window.location.href = '/s'}>
                Back to main page
            </PrimaryButton>
        </div>
    </Page>
};

export default WrongUserType;
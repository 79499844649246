export const bikeTypeOptions = [
    { label: 'Mountainbike', key: 'mountainBike' },
    { label: 'Rennrad', key: 'roadBike' },
    { label: 'Triathlon', key: 'triathlon' },
    { label: 'Cross/Gravel-Fahrrad', key: 'crossGravel' },
    { label: 'Bahnrad/Fixie', key: 'trackBike' },
    { label: 'Downhill-Fahrrad', key: 'downhillBike' },
    { label: 'Stadtfahrrad', key: 'cityBike' },
    { label: 'Sonstiges', key: 'others' },
];

export const notNeededFields = [
    "bikeTypes",
    "isAvailableOnline",
    "filterAttributes",
];

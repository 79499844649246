
import Chart from '../assets/chart.png'
import Fitting from '../assets/fitting.png'
import Course from '../assets/course.png'
import Aero from '../assets/aero.png'
import Guide from '../assets/guide.png'
import Event from '../assets/event.png'
import Whistle from '../assets/whistle.png'
import Faces from '../assets/attributes/faces.png';

export const categoryImages = { 
    leistungsdiagnostik: Chart,
    bikefitting: Fitting,
    kurse: Course,
    coaching: Whistle,
    aerofitting: Aero,
    guides: Guide,
    veranstaltungen: Event,
    events: Faces,
};
import React from 'react';

export const BookingDetails = ({ bookingStart, bookingEnd }) => {
    const options = { weekday: 'short', month: 'short', day: 'numeric' };
    const formattedStartDate = bookingStart.toLocaleDateString(undefined, options);
    const formattedEndDate = bookingEnd.toLocaleDateString(undefined, options);

    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedStartTime = bookingStart.toLocaleTimeString(undefined, timeOptions);
    const formattedEndTime = bookingEnd.toLocaleTimeString(undefined, timeOptions);

    return (
        <div>
            <div style={{ fontWeight: '600', fontSize: '16px' }}>
                {formattedStartDate} - {formattedEndDate}
            </div>
            <div style={{ fontWeight: '400', fontSize: '16px' }}>
                {formattedStartTime} - {formattedEndTime}
            </div>
        </div>
    );
};

export const parseBankInputLabel = (camelString) => {
    if (camelString == "iban") {
        return "IBAN";
    } else {
        return camelString
            .replace(/([A-Z])/g, (match) => ` ${match}`)
            .replace(/^./, (match) => match.toUpperCase())
            .trim();
    };
};

export const maskString = (str) => {
    if (str.length <= 4) {
        return str;
    };

    const lastFourChars = str.slice(-4);
    const maskedChars = '*'.repeat(str.length - 4);

    return maskedChars + lastFourChars;
};

export const neededKycFields = (type = "NATURAL") => {
    if (type == "NATURAL") {
        return [
            "IDENTITY_PROOF"
        ];
    } else {
        return [
            "IDENTITY_PROOF",
            "ARTICLES_OF_ASSOCIATION",
            "REGISTRATION_PROOF",
        ];
    };
};

export const convertKycFieldToText = (kycField) => {
    const fieldTranslations = {
        "IDENTITY_PROOF": "Identitätsnachweis",
        "ARTICLES_OF_ASSOCIATION":"Satzung",
        "REGISTRATION_PROOF":"Gewerbeanmeldung",
    }
    return fieldTranslations[kycField]
}

export const attachAuthorAndImagesToListings = (listings) => {
    if (listings?.data?.length) {
        const data = [];
        listings.data.map(l => {
            l.author = listings.included.find(item => item.type == "user" && item.id.uuid == l.relationships.author.data.id.uuid);
            l.images = l.relationships.images.data.map(imageData => {
                return listings.included.find(item => item.type === "image" && item.id.uuid === imageData.id.uuid);
            });
            data.push(l);
        });
        return data;
    };
    return [];
};

export const isCorrectUserType = (currentUser, typeRequired) => {
    if (!currentUser) return true;

    if (typeRequired) {
        if (currentUser) {
            const {
                isProvider,
                isCustomer,
            } = currentUser.attributes.profile.publicData;
            if (
                (typeRequired == 'provider' && isProvider) ||
                (typeRequired == 'customer' && isCustomer)
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        };
    } else {
        return true;
    };
};

export const getReviewsRatingData = (reviewsArray = []) => {
    if (reviewsArray.length == 0) {
        return 0;
    };

    const stars = reviewsArray.filter(r => r.attributes.state == "public").map(r => {
        return r.attributes.rating;
    });

    const roundedNumber = Number((stars.reduce((a, b) => a + b) / stars.length).toFixed(1));
    return roundedNumber % 1 === 0 ? roundedNumber.toFixed(0) : roundedNumber.toFixed(1);
};

function addFiveMinutesIfNeeded(date) {
    if (new Date(date).getMinutes() === 55) {
        const updatedDate = new Date(date);
        updatedDate.setMinutes(updatedDate.getMinutes() + 5);
        return updatedDate;
    } else {
        return date;
    };
};

export const groupedSlotsByDay = (slots) => {
    const groupedData = {};

    slots.forEach(item => {
        const newItem = { ...item };
        const startDate = new Date(item.attributes.start);
        const day = startDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });

        newItem.attributes.end = addFiveMinutesIfNeeded(newItem.attributes.end);

        if (groupedData[day]) {
            groupedData[day].push(newItem);
        } else {
            groupedData[day] = [newItem];
        }
    });

    return groupedData;
};

function timeToMinutes(timeStr) {
    const [hours, minutes] = timeStr.split(":").map(Number);
    return hours * 60 + minutes;
};

function minutesToTime(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${String(hours).padStart(2, "0")}:${String(remainingMinutes).padStart(2, "0")}`;
};

export const fixAvailabilityPlan = (plan) => {
    const copy = JSON.parse(JSON.stringify(plan));
    copy.activePlanDays?.forEach(day => {
        if (copy[day]) {
            copy[day]?.forEach(entry => {
                const endTimeMinutes = timeToMinutes(entry.endTime);
                if (endTimeMinutes % 60 === 0) {
                    const updatedEndTimeMinutes = endTimeMinutes - 5;
                    entry.endTime = minutesToTime(updatedEndTimeMinutes);
                };
            });
        };
    });

    return copy;
};

export const resetAvailabilityPlan = (plan) => {
    const copy = JSON.parse(JSON.stringify(plan));
    copy.entries.forEach(entry => {
        const endTimeMinutes = timeToMinutes(entry.endTime);
        if (endTimeMinutes % 60 === 55) {
            const updatedEndTimeMinutes = endTimeMinutes + 5;
            entry.endTime = minutesToTime(updatedEndTimeMinutes);
        };
    });

    return copy;
};

export const getNeededSlots = (monthlyTimeSlots, selectedDate) => {
    if (!selectedDate) {
        return [];
    } else {
        const formattedDate = selectedDate.date.toISOString().substring(0, 7);
        const neededMonthSlots = monthlyTimeSlots[formattedDate]?.timeSlots || [];
        const neededDaySlots = neededMonthSlots.filter(s => {
            return s.attributes.start.getDate() == selectedDate.date.getDate();
        });

        return neededDaySlots;
    };
};

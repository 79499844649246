import React from 'react';
import { Modal, Button } from '../../components';
import css from './ConfirmModal.module.css';

const ConfirmModal = (props) => {
    const {
        isModalOpen,
        setIsModalOpen,
        contentClassName,
        modalTitle,
        modalMessage,
        actionBtnMsg,
        onActionBtnClk,
        isDangerAction = true,
    } = props;

    return <div>
        <Modal
            id="ConfirmModal"
            isOpen={isModalOpen}
            onClose={() => {
                setIsModalOpen(false);
            }}
            usePortal
            contentClassName={contentClassName}
            onManageDisableScrolling={() => { }}
        >
            <div>
                <div className={css.modalTitle}>{modalTitle}</div>
                <p className={css.modalMessage}>{modalMessage}</p>
                <div className={css.modalButtonsWrapper}>
                    <Button
                        className={isDangerAction ? css.dangerBtn : css.successBtn}
                        onClick={() => {
                            setIsModalOpen(false);
                            onActionBtnClk();
                        }}
                    >
                        {actionBtnMsg}
                    </Button>
                </div>
            </div>
        </Modal>
    </div>
};

export default ConfirmModal
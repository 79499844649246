import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';
import Cards from 'react-credit-cards';
import { PrimaryButtonInline } from '../Button/Button';
import css from './CreditCard.module.css';
import '../../styles/reactCreditCards.css';

const CreditCard = (props) => {
    const {
        handleSubmit,
        saveButtonText = 'Save',
        viewOnly,
        savedCard,
        intl,
    } = props;

    const [cardData, setCardData] = useState({
        cvc: '',
        expiry: savedCard ? savedCard.expiry : '',
        focus: 'number',
        name: intl.formatMessage({ id: "CreditCardForm.paymentCard" }),
        number: savedCard ? savedCard.number.replaceAll("X", "•") : '',
    });
    const [error, setError] = useState('');

    const isCardNumberValid = (number) => {
        if (number.length <= 16) {
            return true;
        } else {
            setError(intl.formatMessage({ id: "CreditCardForm.paymentCardError1" }));
            return false;
        };
    };

    const isCardExpiryValid = (number) => {
        if (number.length <= 4) {
            if (number.length <= 2 && Number(number) > 12) {
                setError(intl.formatMessage({ id: "CreditCardForm.paymentCardError2" }));
                return false;
            } else {
                return true;
            };

        } else {
            setError(intl.formatMessage({ id: "CreditCardForm.paymentCardError3" }));
            return false;
        };
    };

    const isCardCvcValid = (number) => {
        if (number.length <= 3) {
            return true;
        } else {
            setError(intl.formatMessage({ id: "CreditCardForm.paymentCardError4" }));
            return false;
        };
    };

    const handleInputFocus = (e) => {
        setCardData({ ...cardData, focus: e.target.name });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let currentFocus = cardData.focus;
        setError('');

        if (name == "number" && value.length == 16) {
            currentFocus = "expiry";
            const nextInputField = document.getElementById("expiry");
            if (nextInputField) { nextInputField.focus() };
        } else if (name == "expiry" && value.length == 4) {
            currentFocus = "cvc";
            const nextInputField = document.getElementById("cvc");
            if (nextInputField) { nextInputField.focus() };
        } else if (name == "cvc" && value.length == 3) {
            currentFocus = "number";
            const nextInputField = document.getElementById("number");
            if (nextInputField) { nextInputField.focus() };
        };

        if (
            isCardNumberValid(name == 'number' ? value : cardData.number) &&
            isCardExpiryValid(name == 'expiry' ? value : cardData.expiry) &&
            isCardCvcValid(name == 'cvc' ? value : cardData.cvc)
        ) {
            setCardData({ ...cardData, [name]: value, focus: currentFocus });
        };

    };

    useEffect(() => {
        if (error) {
            const timeout = setTimeout(() => {
                setError(null);
            }, 3000)
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [error]);

    return (<div className={css.cardWrapper}>
        <Cards
            cvc={cardData.cvc}
            expiry={cardData.expiry}
            focused={cardData.focus}
            name={cardData.name}
            number={cardData.number}
        />

        {viewOnly ? null :
            <form>
                <input
                    type="number"
                    id="number"
                    name="number"
                    placeholder={intl.formatMessage({ id: "CreditCardForm.paymentCardNumberPlaceHolder" })}
                    value={cardData.number}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    autoFocus={true}
                    className={css.cardNumberInput}
                />

                <div className={css.flexRowContainer}>
                    <input
                        type="number"
                        id="expiry"
                        name="expiry"
                        placeholder={intl.formatMessage({ id: "CreditCardForm.paymentCardExpiryPlaceHolder" })}
                        value={cardData.expiry}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        className={css.cardExpiryInput}
                    />

                    <input
                        type="number"
                        id="cvc"
                        name="cvc"
                        placeholder={intl.formatMessage({ id: "CreditCardForm.paymentCardCvcPlaceHolder" })}
                        value={cardData.cvc}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        className={css.cardCvcInput}
                    />
                </div>
                {error ? <div className={`${css.cardError} ${error ? css.fadeOut : ''}`}>
                    {error}
                </div> : null}

                <PrimaryButtonInline
                    type="button"
                    className={css.cardSubmit}
                    onClick={() => handleSubmit(cardData)}
                    disabled={
                        cardData.number?.length !== 16 ||
                        cardData.expiry?.length !== 4 ||
                        cardData.cvc?.length !== 3
                    }
                >
                    {saveButtonText}
                </PrimaryButtonInline>
            </form>
        }
    </div>);
};

const CreditCardForm = compose(
    injectIntl
)(CreditCard);

export default CreditCardForm;

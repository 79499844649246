import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';
import { useConfiguration } from '../../context/configurationContext';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { getReviewsRatingData } from '../../util/misc';
import { isBookingProcessAlias } from '../../transactions/transaction';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
// import { queryUserReviews } from '../../containers/ProfilePage/ProfilePage.duck';
import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';
import starIcon from "../../assets/icons/star.png";
import chargeIcon from "../../assets/icons/charge.png";
import Earth from "../../assets/earth.png";
import Pin from "../../assets/pin.png";
import Bike from "../../assets/bike.png";
import { bikeTypeOptions } from '../../util/listingShared';
import { categoryImages } from '../../util/categoryImages';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const ListingCard = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    currentUser,
    fullReviews,
    onUpdateProfile,
    // onQueryUserReviews,
  } = props;

  const [liked, setLiked] = useState(false);
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const userName = author.attributes.profile.publicData.userName;
  const nameToDisplay = userName ? userName : authorName;
  const firstImage = currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const shouldShowLikeButton = currentUser && currentUser.attributes.profile.publicData.isCustomer;

  const handleClick = (event) => {
    event.preventDefault();
    setLiked(!liked);
    const likedListings = currentUser && currentUser.attributes.profile.publicData.favoriteListings;
    let newListings = likedListings ? likedListings : [];
    if (isLiked()) {
      newListings = likedListings.filter(l => l !== currentListing.id.uuid);
    } else {
      newListings.push(currentListing.id.uuid);
    };

    onUpdateProfile({
      publicData: {
        favoriteListings: newListings
      }
    });
  };

  const heartIconClass = liked ? `${css.heartIcon} ${css.liked}` : css.heartIcon;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);

  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;

  const isLiked = () => {
    const likedListings = currentUser && currentUser.attributes.profile.publicData.favoriteListings;
    return likedListings && likedListings.includes(currentListing.id.uuid);
  };

  const numberOfSelectedServices = () => {
    let counter = 0;
    const neededObj = publicData.attributes || {};
    delete neededObj.changed;

    Object.keys(neededObj).map(key => {
      const neededValue = neededObj[key];
      if (neededValue) {
        if (Array.isArray(neededValue)) {
          counter += neededValue.length;
        } else {
          counter += 1;
        }
      };
    });
    return counter;
  };

  const bikeTypes = publicData && publicData.bikeTypes && publicData.bikeTypes.map(bikeTypeKey => {
    return bikeTypeOptions.find(b => b.key == bikeTypeKey)?.label ? bikeTypeOptions.find(b => b.key == bikeTypeKey)?.label : null;
  });

  let types = bikeTypes && bikeTypes.join(', ');
  if (types && types?.length > 14) {
    types = types?.slice(0, 14) + '...';
  };

  const listingLocation = publicData && publicData.location && publicData.location.address;
  let listingAddress = listingLocation;
  if (listingAddress && listingAddress?.length > 14) {
    listingAddress = listingAddress.slice(0, 14) + '...';
  };

  const avgReviews = publicData?.reviewScore / 10

  const instantBookingLabel = publicData.instantBooking ? <div className={css.instantBookLabel}>
    <img src={chargeIcon} />
    <FormattedMessage id="ListingCard.instantBookingLabel" />
  </div> : null;

  useEffect(() => {
    setLiked(isLiked());
  }, [currentUser]);

  // useEffect(() => {
  //   if (author && author.id) {
  //     onQueryUserReviews(author.id);
  //   };
  // }, []);


  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      {shouldShowLikeButton ? <div className={css.heartImageWrapper}>
        <div className={css.listingLike}>
          <div className={heartIconClass} onClick={handleClick}></div>
        </div>
      </div> : null}
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
        {instantBookingLabel}
      </AspectRatioWrapper>

      <div className={css.mainWrapper}>
        <div className={css.categorywrapper}>
          <div className={css.categories}>
            <img src={categoryImages[publicData.category]} alt={publicData.category} className={css.categoryImage} />
            <span className={css.categoryTitle}>{publicData.category?.charAt(0)?.toUpperCase() + publicData.category?.slice(1)}</span>
          </div>
        </div>
        <div className={css.bikeTypewrapper}>
          <span className={css.categoryTitle}>{numberOfSelectedServices()} Optionen</span>
        </div>
        {publicData.isAvailableOnline ?
          <div className={css.availableOnlineWrapper}>
            <div>
              <img src={Earth} />
            </div>
          </div> : null}
      </div>
      <div className={css.detailsWrapper}>
        <div className={css.pinWrapper}>
          <img src={Pin} />
          <div>{listingAddress} •</div>
        </div>
        <div className={css.pinWrapper}>
          <img src={Bike} />
          <div>{types} •</div>
        </div>

        <div className={css.reviewWrapper}>
          <img src={starIcon} />
          {avgReviews ? avgReviews : 0}/5
        </div>
      </div>

      <div className={css.info}>
        <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          {isBookingProcessAlias(publicData?.transactionProcessAlias) ? (
            <div className={css.perUnit}>
              <FormattedMessage
                id="ListingCard.perUnit"
                values={{ unitType: publicData?.unitType }}
              />
            </div>
          ) : null}

        </div>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>

          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName: nameToDisplay }} />
            </div>
          ) : null}
        </div>
      </div>

    </NamedLink>
  );
};

ListingCard.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCard.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

const mapStateToProps = state => {
  const {
    currentUser,
  } = state.user;
  const {
    fullReviews,
  } = state.ProfilePage;
  return {
    currentUser,
    fullReviews,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateProfile: data => dispatch(updateProfile(data)),
    // onQueryUserReviews: userId => dispatch(queryUserReviews(userId)),
  };
};

const ListingCardComponent = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingCard);

export default ListingCardComponent;
